//
//
//
//
//
//
//
//
//
//
//

import Viewport from '@/mixins/viewport';

export default {
  mixins: [Viewport],

  data: () => {
    return {
      imageUrl: require('@/assets/img/home/homepage-banner-mobile-v4.jpg'),
      desktopImageUrl: require('@/assets/img/home/homepage-banner-v4.jpg'),
      promoLink:
        'https://promo.mamikos.com/promo-launching-mamiservice-selain-terimaberes-juga-bisa-dapatkan-harga-terbaik/',
    };
  },
};
