//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    endDateTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      diff: 0,
    };
  },
  computed: {
    days() {
      return this.twoDigits(Math.floor(this.diff / (1000 * 60 * 60 * 24)));
    },
    hours() {
      return this.twoDigits(Math.floor((this.diff / (1000 * 60 * 60)) % 24));
    },
    minutes() {
      return this.twoDigits(Math.floor((this.diff / 1000 / 60) % 60));
    },
    seconds() {
      return this.twoDigits(Math.floor((this.diff / 1000) % 60));
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    twoDigits(value) {
      return ('0' + value.toString()).slice(-2);
    },
    countdownTimer() {
      const diff = new Date(this.endDateTime) - new Date();

      if (diff <= 0) {
        clearInterval(this.interval);
        this.$emit('countdown-end');
      }

      this.diff = diff;
    },
    startCountdown() {
      this.interval = setInterval(() => {
        this.countdownTimer();
      }, 1000);
    },
  },
};
