//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgText, BgButton, BgGrid, BgGridItem } from 'Bangul-vue';
import viewport from '~/mixins/viewport';

export default {
  components: {
    BgText,
    BgButton,
    BgGrid,
    BgGridItem,
  },
  mixins: [viewport],
  methods: {
    openMoreFAQ() {
      const openWindow = window.open(
        'https://drive.google.com/file/d/1xMYNYHqrsFxV-2tePrtlsgBdahTQF3tr/view?usp=sharing'
      );

      openWindow.opener = null;
    },
  },
};
