// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/home/about_us/aboutus-1.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/home/about_us/aboutus-2.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/home/about_us/aboutus-3.jpg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/img/home/about_us/aboutus-4.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aboutus[data-v-e9da5d3c]{margin-top:164px}.aboutus__description[data-v-e9da5d3c]{max-width:807px;margin:67px auto 48px;text-align:center;flex:1}.aboutus__description-heading[data-v-e9da5d3c]{margin-bottom:12px}.aboutus__card[data-v-e9da5d3c]{position:relative;display:flex;height:406px;background-repeat:no-repeat;background-size:cover;border-radius:8px;align-items:center;justify-content:center}.aboutus__card[data-v-e9da5d3c]:before{position:absolute;top:0;right:0;bottom:0;left:0;content:\"\";background-color:rgba(0,0,0,.5);border-radius:8px}.aboutus__card--landscape[data-v-e9da5d3c]{width:100%;height:189px;margin-right:0;margin-bottom:26px}.aboutus__card--image1[data-v-e9da5d3c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.aboutus__card--image2[data-v-e9da5d3c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.aboutus__card--image3[data-v-e9da5d3c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.aboutus__card--image4[data-v-e9da5d3c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}@media(max-width:768px){.aboutus__card[data-v-e9da5d3c]{height:120px}.aboutus__card--landscape[data-v-e9da5d3c]{height:78px;margin-right:16px;margin-bottom:0}.aboutus__card--landscape[data-v-e9da5d3c]:last-child{margin-right:0}}.aboutus__card-landscape-wrapper[data-v-e9da5d3c]{display:flex;flex-direction:column}@media(max-width:768px){.aboutus[data-v-e9da5d3c]{margin-top:72px}.aboutus__card-landscape-wrapper[data-v-e9da5d3c]{flex-direction:row}}.aboutus__card-text[data-v-e9da5d3c]{position:absolute;color:#fff;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
