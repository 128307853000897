//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgText, BgGrid, BgGridItem } from 'Bangul-vue';

import { MAMISERVICE_WHATSAPP_NUMBER } from '@/constants/contact';
import { MAMISERVICE_ORDER_LINK } from '@/constants/config';
import Viewport from '@/mixins/viewport';

export default {
  components: {
    BgText,
    BgGrid,
    BgGridItem,
  },
  mixins: [Viewport],
  data() {
    return {
      serviceOrderLink: MAMISERVICE_ORDER_LINK,
      mamiserviceWhatsappNumber: MAMISERVICE_WHATSAPP_NUMBER,
    };
  },
};
