//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgGrid, BgGridItem, BgText, BgImage } from 'Bangul-vue';

import Viewport from '@/mixins/viewport';

export default {
  components: {
    BgGrid,
    BgGridItem,
    BgText,
    BgImage,
  },

  mixins: [Viewport],

  data() {
    return {
      highlights: [
        {
          title: 'Layanan Profesional dan Terpercaya',
          description: '',
          imageUrl: require('@/assets/img/home/highlight/professional-service.svg'),
        },
        {
          title: 'Staff Berkualitas dan Handal',
          description: '',
          imageUrl: require('@/assets/img/home/highlight/professional-staffs.svg'),
        },
        {
          title: 'Memberikan Harga Terbaik',
          description: '',
          imageUrl: require('@/assets/img/home/highlight/best-prices.svg'),
        },
      ],
    };
  },
};
