//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgText } from 'Bangul-vue';

import Viewport from '@/mixins/viewport';

export default {
  components: {
    BgText,
  },

  mixins: [Viewport],

  data() {
    return {
      howTos: [
        {
          bangulIconName: 'computer',
          title: 'Masukkan kota & lokasi pemesanan',
          description: '',
          imageDesktop: require('@/assets/img/home/how-tos/how-to-1-desktop.jpg'),
          imageMobile: require('@/assets/img/home/how-tos/how-to-1.png'),
        },
        {
          bangulIconName: 'computer',
          title: 'Pilih tipe perawatan yang diinginkan',
          description: '',
          imageDesktop: require('@/assets/img/home/how-tos/how-to-2-desktop.jpg'),
          imageMobile: require('@/assets/img/home/how-tos/how-to-2.png'),
        },
        {
          bangulIconName: 'computer',
          title: 'Masukkan jumlah pemesanan, klik “Lanjutkan”',
          description: '',
          imageDesktop: require('@/assets/img/home/how-tos/how-to-3-desktop.jpg'),
          imageMobile: require('@/assets/img/home/how-tos/how-to-3.png'),
        },
        {
          bangulIconName: 'computer',
          title: 'Pilih jam dan klik “Pesan Sekarang”',
          description: '',
          imageDesktop: require('@/assets/img/home/how-tos/how-to-4-desktop.jpg'),
          imageMobile: require('@/assets/img/home/how-tos/how-to-4.png'),
        },
        {
          bangulIconName: 'computer',
          title: 'Isi data diri dan pilih metode pembayaran',
          description: '',
          imageDesktop: require('@/assets/img/home/how-tos/how-to-5-desktop.jpg'),
          imageMobile: require('@/assets/img/home/how-tos/how-to-5.png'),
        },
      ],
    };
  },

  computed: {
    howToComponent() {
      if (this.viewport.isMobile) {
        return () => import('../howto/slides/mobile/HowToSlides');
      }

      return () => import('../howto/slides/dekstop/HowToSlides');
    },
  },
};
