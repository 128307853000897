//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgGrid, BgGridItem } from 'Bangul-vue';

import SectionHeader from '@/components/page-features/home/SectionHeader';
import SectionAboutUs from '@/components/page-features/home/SectionAboutUs';
import SectionHighlight from '@/components/page-features/home/SectionHighlight';
import SectionBestOffer from '@/components/page-features/home/best-offer/SectionBestOffer';
import SectionHowto from '@/components/page-features/home/howto/SectionHowTo';
import SectionFaq from '@/components/page-features/home/SectionFAQ';
import SectionTestimony from '@/components/page-features/home/SectionTestimony';
import viewport from '~/mixins/viewport';

export default {
  name: 'HomePage',
  components: {
    BgGrid,
    SectionTestimony,
    BgGridItem,
    SectionHeader,
    SectionHowto,
    SectionAboutUs,
    SectionHighlight,
    SectionBestOffer,
    SectionFaq,
  },
  mixins: [viewport],
  head: {
    link: [
      {
        rel: 'canonical',
        href: 'http://mamiservice.id',
      },
      {
        rel: 'preload',
        as: 'image',
        href: require('@/assets/logo/mamiservice-logo.png'),
      },
      {
        rel: 'preload',
        as: 'image',
        href: require('@/assets/img/home/homepage-banner-v4.jpg'),
      },
      {
        rel: 'preload',
        as: 'image',
        href: '/pictures/promo-banner-v2.jpeg',
      },
    ],
  },
};
