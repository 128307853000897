//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgSwiperSlide, BgSwiper, BgText, BgIcon, BgImage } from 'Bangul-vue';

import Viewport from '@/mixins/viewport';
import testimonies from '@/static/testimonies.json';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Testimony',

  components: {
    BgSwiperSlide,
    BgSwiper,
    BgText,
    BgIcon,
    BgImage,
  },

  mixins: [Viewport],

  data() {
    return {
      testimonies,
      activeDots: 0,
    };
  },
  computed: {
    isExceedTheShownLimit() {
      if (this.testimonies.length <= 3) {
        return false;
      }

      return true;
    },
    swiperOptions() {
      return {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          // when window width is >= 769px
          769: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
          },
        },
      };
    },
    firstSwipperIndex() {
      return this.activeDots === 0;
    },
    lastSwipperIndex() {
      if (this.viewport.isMobile) {
        return this.activeDots === this.testimonies.length - 1;
      }

      return this.activeDots === this.testimonies.length - 3;
    },
  },
  mounted() {
    this.handleIndexChangeSwipper();
  },
  methods: {
    goToPreviousSlide() {
      this.$refs.testimonySwiper.$bgSwiper.slidePrev();
    },
    goToNextSlide() {
      this.$refs.testimonySwiper.$bgSwiper.slideNext();
    },
    handleIndexChangeSwipper() {
      const that = this;
      setTimeout(() => {
        this.$refs.testimonySwiper.$bgSwiper.on('activeIndexChange', e => {
          that.activeDots = that.$refs.testimonySwiper.$bgSwiper.activeIndex;
        });
      }, 300);
    },
  },
};
