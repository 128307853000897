//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _find from 'lodash/find';
import { BgText, BgButton } from 'Bangul-vue';
import SectionBestOfferCountdown from './BestOfferCountdown';
import bestOfferData from '@/datas/best-offer.json';
import Viewport from '@/mixins/viewport';

export default {
  components: {
    BgText,
    BgButton,
    SectionBestOfferCountdown,
  },
  mixins: [Viewport],
  data() {
    return {
      bestOfferCountdown: bestOfferData,
    };
  },
  computed: {
    bestOfferDescription() {
      return this.bestOfferActive.description.join('\n');
    },
    bestOfferActive() {
      if (this.bestOfferCountdown.length < 1) return null;

      const today = new Date();

      const result = _find(
        this.bestOfferCountdown,
        bestOffer => new Date(bestOffer.end_date) > today
      );

      return result;
    },
    backgroundSection() {
      return this.viewport.isMobile
        ? this.bestOfferActive.banner_mobile
        : this.bestOfferActive.banner;
    },
  },
  methods: {
    goToDetail() {
      const urlPath = this.bestOfferActive.url;

      this.$router.push({ path: urlPath });
    },
    handleCountdownEnd() {
      window.location.reload();
    },
  },
};
